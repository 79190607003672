<template>
  <v-row>
    <v-row style="width: 100%">
      <v-col cols="12" md="8"
        ><h5>{{ title }}</h5></v-col
      >
    </v-row>

    <v-row v-if="mounted">
      <DocumentItem
        v-for="(item, index) in allDocuments"
        :key="index"
        :document="item"
        :context="context"
        :isAffiliation="isAffiliation"
        @onUploaded="documentUploaded"
        :ID_DocCompteRenduAg="affiliation.ID_DocCompteRenduAg"
      />
    </v-row>
    <v-row v-if="mounted" style="margin: 10vh 10px 15px">
      <v-divider class="divider" color="#1A237E"></v-divider>
      <div class="engagement_etatique">
        <!-- <span v-if="isClub"
          >Contrat club :
          {{ this.affiliation.isContrat_Club_EA ? "Oui" : "Non" }}</span
        > -->
        <span v-if="isEA"
          >Convention d’établissement :
          {{ this.affiliation.isContrat_Club_EA ? "Oui" : "Non" }}</span
        >
        <span
          >Contrat d’engagement républicain :
          {{ this.affiliation.isRepublicain_engagement ? "Oui" : "Non" }}</span
        >
      </div>
    </v-row>
    <v-row v-if="isAffiliation" style="width: 100%">
      <v-col cols="12">
        <v-btn
          type="button"
          class="btn btn-primary"
          :disabled="!stepIsValid"
          @click="() => { this.$router.push({ name: 'affiliations__gestion_des_salaries' }) }"
          >Étape suivante</v-btn
        >
      </v-col>
    </v-row>
  </v-row>
</template>

<script>
import DocumentItem from "@/components/GestionDesDocuments/GestionDesDocuments__Item.vue";
import { mapGetters, mapActions } from "vuex";
import { bus } from "@/main";

export default {
  components: { DocumentItem },

  data: () => ({
    affiliation: {},
    allDocumentType: [],
    structureType: {},
    mounted: false,
    structure: {},

    stepIsValid: false,
  }),

  async created() {
    await this.setData();
    this.mounted = true;
    return;
  },

  computed: {
    ...mapGetters("structure", ["currentStructure", "currentStructureType"]),

    title() {
      if (this.structureType.EST_Club && this.isAffiliation) {
        return "Etape 3 - Saisie des documents";
      }
      return "Documents officiels";
    },

    allDocuments() {
      let docs = [this.DOC_Status];
      if (this.structureType.EST_Club && !this.isAffiliation) {
        docs.push(this.DOC_CompteRenduAg);
        docs.push(this.DOC_RapportTresorerie);
        docs.push(this.DOC_Mandat);
        if (this.structureType.SlugTypeStructure == "club_association") {
          docs.push(this.DOC_ParutionJO);
          docs.push(this.DOC_RecepissePrefectoralDeclaration);
          //docs.push(this.DOC_ContratClub);
          docs.push(this.DOC_ReglementInterieur);
        } else if (
          this.structureType.SlugTypeStructure == "club_section_association"
        ) {
          docs.push(this.DOC_ParutionJO);
          docs.push(this.DOC_RecepissePrefectoralDeclaration);
          docs.push(this.DOC_ReglementInterieur);
        } else if (
          this.structureType.SlugTypeStructure == "club_etablissement_affilie"
        ) {
          docs.push(this.DOC_AttestationAssurance);
          docs.push(this.DOC_CarteProfessionnelle);
          docs.push(this.DOC_DiplomeEncadrant);
        }
      } else if (this.structureType.EST_Club && this.isAffiliation) {
        if (
          this.structureType.SlugTypeStructure == "club_association"
          || this.structureType.SlugTypeStructure == "club_section_association"
        ) {
          docs.unshift(this.DOC_CompteRenduAg);
          docs.push(this.DOC_ReglementInterieur);
          docs.push(this.DOC_RapportTresorerie);
        }

        if (this.structureType.SlugTypeStructure === 'club_etablissement_affilie') {
          docs.unshift(this.DOC_DiplomeEncadrant);
          docs.unshift(this.DOC_CarteProfessionnelle);
          docs.unshift(this.DOC_AttestationAssurance);
        }
      } else if (
        this.structureType.SlugTypeStructure == "service_federal_ffme"
      ) {
        docs.push(this.DOC_ReglementInterieur);
        docs.push(this.DOC_ReglementFinancier);
        docs.push(this.DOC_ReglementAntiDopage);
        docs.push(this.DOC_ReglementMedical);
        docs.push(this.DOC_ReglementDisciplinaire);
        docs.push(this.DOC_Rgpd);
        docs.push(this.DOC_Rib);
      } else if (this.structureType.SlugTypeStructure == "ligue") {
        docs.push(this.DOC_BilanFinancier);
        docs.push(this.DOC_CompteResultat);
        docs.push(this.DOC_CompteRenduAg);
      } else if (this.structureType.SlugTypeStructure == "comite_territorial") {
        docs.push(this.DOC_BilanFinancier);
        docs.push(this.DOC_CompteResultat);
        docs.push(this.DOC_CompteRenduAg);
      }

      // }else if (this.structureType.SlugTypeStructure == "ligue"){

      // }
      return docs;
    },

    DOC_Rib() {
      if (this.affiliation.DOC_Rib) return this.affiliation.DOC_Rib;
      return this.getDefaultDoc("rib");
    },
    DOC_CompteResultat() {
      if (this.affiliation.DOC_CompteResultat)
        return this.affiliation.DOC_CompteResultat;
      return this.getDefaultDoc("compte_resultat");
    },
    DOC_BilanFinancier() {
      if (this.affiliation.DOC_BilanFinancier)
        return this.affiliation.DOC_BilanFinancier;
      return this.getDefaultDoc("bilan_financier");
    },
    DOC_Status() {
      if (this.affiliation.DOC_Status) return this.affiliation.DOC_Status;
      return this.getDefaultDoc("status");
    },
    DOC_R() {
      if (this.affiliation.DOC_Status) return this.affiliation.DOC_Status;
      return this.getDefaultDoc("status");
    },

    DOC_ContratClub() {
      if (this.affiliation.DOC_ContratClub)
        return this.affiliation.DOC_ContratClub;
      return this.getDefaultDoc("contrat_club");
    },

    DOC_CompteRenduAg() {
      if (this.affiliation.DOC_CompteRenduAg)
        return this.affiliation.DOC_CompteRenduAg;
      return this.getDefaultDoc("compte_rendu_ag");
    },
    DOC_Mandat() {
      if (this.affiliation.DOC_Mandat) return this.affiliation.DOC_Mandat;
      return this.getDefaultDoc("mandat_représentant_légal");
    },

    DOC_RapportTresorerie() {
      if (this.affiliation.DOC_RapportTresorerie)
        return this.affiliation.DOC_RapportTresorerie;
      return this.getDefaultDoc("rapport_tresorerie");
    },

    DOC_ParutionJO() {
      if (this.affiliation.DOC_ParutionJO)
        return this.affiliation.DOC_ParutionJO;
      return this.getDefaultDoc("parution_jo");
    },

    DOC_AttestationAssurance() {
      if (this.affiliation.DOC_AttestationAssurance)
        return this.affiliation.DOC_AttestationAssurance;
      return this.getDefaultDoc("attestation_assurance");
    },

    DOC_CarteProfessionnelle() {
      if (this.affiliation.DOC_CarteProfessionnelle)
        return this.affiliation.DOC_CarteProfessionnelle;
      return this.getDefaultDoc("copie_cartes_professionnelles");
    },

    DOC_DiplomeEncadrant() {
      if (this.affiliation.DOC_DiplomeEncadrant)
        return this.affiliation.DOC_DiplomeEncadrant;
      return this.getDefaultDoc("diplome_encadrant");
    },

    DOC_RecepissePrefectoralDeclaration() {
      if (this.affiliation.DOC_RecepissePrefectoralDeclaration)
        return this.affiliation.DOC_RecepissePrefectoralDeclaration;
      return this.getDefaultDoc("recepisse_prefectoral_declaration");
    },

    DOC_ReglementInterieur() {
      if (this.affiliation.DOC_ReglementInterieur)
        return this.affiliation.DOC_ReglementInterieur;
      return this.getDefaultDoc("reglement_interieur");
    },

    DOC_ReglementFinancier() {
      if (this.affiliation.DOC_ReglementFinancier)
        return this.affiliation.DOC_ReglementFinancier;
      return this.getDefaultDoc("reglement_financier");
    },

    DOC_ReglementMedical() {
      if (this.affiliation.DOC_ReglementMedical)
        return this.affiliation.DOC_ReglementMedical;
      return this.getDefaultDoc("reglement_medical");
    },

    DOC_ReglementAntiDopage() {
      if (this.affiliation.DOC_ReglementAntiDopage)
        return this.affiliation.DOC_ReglementAntiDopage;
      return this.getDefaultDoc("reglement_anti_dopage");
    },

    DOC_ReglementDisciplinaire() {
      if (this.affiliation.DOC_ReglementDisciplinaire)
        return this.affiliation.DOC_ReglementDisciplinaire;
      return this.getDefaultDoc("reglement_disciplinaire");
    },

    DOC_Rgpd() {
      if (this.affiliation.DOC_Rgpd) return this.affiliation.DOC_Rgpd;
      return this.getDefaultDoc("rgpd");
    },

    isClub() {
      let typestructure = this.structure
        ? this.structure.LIST_StructureType.SlugTypeStructure
        : this.currentStructureType.SlugTypeStructure;
      return typestructure == "club_association";
    },

    isEA() {
      let typestructure = this.structure
        ? this.structure.LIST_StructureType.SlugTypeStructure
        : this.currentStructureType.SlugTypeStructure;
      return typestructure == "club_etablissement_affilie";
    },
  },

  methods: {
    ...mapActions("structure", [
      "getStructureAffiliation",
      "updateAffiliationDocument",
      "getStructureType",
      "getStructureByID",
    ]),
    ...mapActions("basics", ["getListTypesDocument"]),

    async setData() {
      let strType = await this.getStructureType({
        ID_Structure: this.context.ID_Structure,
      });
      this.structureType = strType.LIST_StructureType;

      // Filter the doc type by structure type !!!
      this.setDocumentType(await this.getListTypesDocument());

      this.affiliation = await this.getStructureAffiliation({
        ID_Structure: this.context.ID_Structure,
        ID_Saison: this.context.ID_Saison,
      });

      const structureID = this.$route.params.id ? parseInt(this.$route.params.id, 10) : this.context.ID_Structure;

      this.structure = await this.getStructureByID(structureID);
      console.log("🚀 ~ setData ~ this.getStructure", this.structure);

      this.validateStep();

      return;
    },

    async documentUploaded() {
      await this.setData();
    },

    setDocumentType(documentType) {
      if (this.structureType.EST_Club) {
        this.allDocumentType = documentType.filter(
          (doc) =>
            doc.LIST_DocumentCategory &&
            doc.LIST_DocumentCategory.SlugCategorieDocument == "documents_club"
        );
      } else if (this.structureType.SlugTypeStructure == "comite_territorial") {
        this.allDocumentType = documentType.filter(
          (doc) =>
            doc.LIST_DocumentCategory &&
            doc.LIST_DocumentCategory.SlugCategorieDocument == "documents_ct"
        );
      } else if (this.structureType.SlugTypeStructure == "ligue") {
        this.allDocumentType = documentType.filter(
          (doc) =>
            doc.LIST_DocumentCategory &&
            doc.LIST_DocumentCategory.SlugCategorieDocument == "documents_ligue"
        );
      } else if (
        this.structureType.SlugTypeStructure == "service_federal_ffme"
      ) {
        this.allDocumentType = documentType.filter(
          (doc) =>
            doc.LIST_DocumentCategory &&
            doc.LIST_DocumentCategory.SlugCategorieDocument == "documents_ffme"
        );
      }
    },

    // Get the document type and generate a default object
    getDefaultDoc(slugType) {
      let doc = {
        CheminDocument: "",
        DateFinValiditeDocument: "",
        EST_Actif: true,
        EST_DocumentValide: false,
        ID_Structure: this.context.ID_Structure,
        ID_Type_Document: "",
        NomDocument: "",
      };
      let type = this.allDocumentType.find(
        (doc) => doc.SlugTypeDocument == slugType
      );
      doc.ID_Type_Document = type?.id;

      return doc;
    },

    async validateStep() {
      let affiliation = await this.getStructureAffiliation(this.context);

      if (this.structureType.EST_Club) {
        if (
          this.structureType.SlugTypeStructure == "club_association"
          || this.structureType.SlugTypeStructure == "club_section_association"
        ) {
          if (affiliation.ID_DocCompteRenduAg) {
            this.stepIsValid = true;
            bus.$emit('onRefreshNavigation', true);
            return true;
          }
        }

        if (this.structureType.SlugTypeStructure === 'club_etablissement_affilie') {
          if (
            affiliation.ID_DocDiplomeEncadrant
            && affiliation.ID_DocCarteProfessionnelle
            && affiliation.ID_DocAttestationAssurance
          ) {
            this.stepIsValid = true;
            bus.$emit('onRefreshNavigation', true);
            return true;
          }
        }
      }

      if (
        affiliation.ID_DocCompteRenduAg
        && affiliation.ID_DocStatus
      ) {
        this.stepIsValid = true;
        bus.$emit('onRefreshNavigation', true);
        return true;
      }

      this.stepIsValid = false;
      return false;
    },
  },

  props: {
    context: {
      type: Object,
    },
    isAffiliation: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="scss" scoped>
.divider {
  border-width: 2px !important;
}
.engagement_etatique {
  margin-top: 2vh;
  width: 100%;
  span {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
  }
}
</style>
