var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{attrs:{"cols":"12 py-0"}},[(_vm.mounted)?_c('v-row',[_c('v-col',{staticClass:"py-0",staticStyle:{"display":"flex","align-items":"center"},attrs:{"cols":"12","md":"9"}},[_c('div',{staticClass:"px-2 py-2"},[_c('span',{staticClass:"font-weight-bold",staticStyle:{"font-size":"0.9rem"}},[_vm._v(" "+_vm._s(_vm.documentType.NomTypeDocument.replace("Statut", "Statut"))+" "+_vm._s(_vm.isAffiliation && (_vm.documentType.SlugTypeDocument === 'compte_rendu_ag' || _vm.documentType.SlugTypeDocument === 'diplome_encadrant' || _vm.documentType.SlugTypeDocument === 'copie_cartes_professionnelles' || _vm.documentType.SlugTypeDocument === 'attestation_assurance') ? '*' : '')+" ")])]),(_vm.isAffiliation && _vm.documentType.SlugTypeDocument === 'reglement_interieur' || _vm.isAffiliation && _vm.documentType.SlugTypeDocument === 'status')?_c('div',{staticStyle:{"font-size":"12px","font-weight":"300"}},[_vm._v(" A transmettre en cas de mise à jour depuis la saison dernière ")]):_vm._e()]),_c('v-col',{staticClass:"text-right",attrs:{"cols":"12","md":"3"}},[(_vm.documents.length && (_vm.ID_DocCompteRenduAg || _vm.documentType.SlugTypeDocument !== 'compte_rendu_ag'))?_c('span',{staticStyle:{"font-size":"13px"}},[_vm._v(" "+_vm._s(_vm.documents && _vm.$moment(_vm.documents[0].Z_DateCreation).format("DD/MM/YYYY")))]):_vm._e(),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-2",attrs:{"disabled":!_vm.context.hasCodeFederal,"fab":"","dark":"","x-small":"","outlined":"","color":_vm.hasDocument ? 'indigo darken-4' : 'grey lighten-1'},on:{"click":_vm.openPreview}},on),[_c('v-icon',[_vm._v("mdi-image")]),(_vm.modalPreviewIsOpen)?_c('modalPreviewDocument',{attrs:{"isOpen":_vm.modalPreviewIsOpen,"context":Object.assign({}, _vm.context,
                {document: _vm.document,
                documentType: _vm.documentType})},on:{"onClose":function($event){_vm.modalPreviewIsOpen = false}}}):_vm._e()],1)]}}],null,false,2374407210)},[_c('span',[_vm._v("Afficher le document")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-2 mr-2",attrs:{"disabled":!_vm.context.hasCodeFederal,"fab":"","dark":"","x-small":"","outlined":"","color":"indigo darken-4"},on:{"click":function($event){$event.preventDefault();_vm.modalHistoryIsOpen = true}}},on),[_c('v-icon',[_vm._v("mdi-history")]),(_vm.modalHistoryIsOpen)?_c('modalHistoryDocument',{attrs:{"isOpen":_vm.modalHistoryIsOpen,"context":Object.assign({}, _vm.context,
                {document: _vm.document,
                documentType: _vm.documentType})},on:{"onClose":function($event){_vm.modalHistoryIsOpen = false}}}):_vm._e()],1)]}}],null,false,486083545)},[_c('span',[_vm._v("Afficher l'historique")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"disabled":!_vm.context.hasCodeFederal,"fab":"","dark":"","outlined":"","x-small":"","color":"indigo darken-4"},on:{"click":_vm.openModalUpload}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-plus")]),(_vm.modalUploadDocumentIsOpen)?_c('modalUploadDocument',{attrs:{"isOpen":_vm.modalUploadDocumentIsOpen,"dateneeded":false,"context":Object.assign({}, _vm.context,
                {document: _vm.document,
                documentType: _vm.documentType})},on:{"onClose":function($event){_vm.modalUploadDocumentIsOpen = false},"onFileUploaded":_vm.fileUploaded}}):_vm._e()],1)]}}],null,false,1837366695)},[_c('span',[_vm._v("Téléverser un nouveau document")])])],1)],1):_vm._e(),_c('hr')],1)}
var staticRenderFns = []

export { render, staticRenderFns }